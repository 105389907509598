<template>
  <div class="content-container">
    <div v-if="this.showError" class="notification-banner-container">
      <notification-banner
      theme='custom'
      title='Content Unavailable'
      :alternate="true"
      :message="this.errorMessage"
      :show-close-button="false" />
    </div>
    <loading-state class='d-block' v-else-if="showLoadingState" />
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { NotificationBanner } from 'rwdi-widgets';
import LoadingState from '@/components/views/LoadingState';

export default {
  name: 'ContentContainer',
  components: {
    NotificationBanner,
    LoadingState
  },
  computed: {
    loggedInUserIsOTPUserAndOnWrongPage() {
      return this.loggedInUser?.is_otp_user && !this.$route.name.includes('ViewerContainer');
    },
    errorMessage() {
      return this.error ? 'Please contact support if you continue to see this error.' : null;
    },
    showError() {
      return this.error != null && this.$route.name !== 'ViewerContainer';
    },
    showLoadingState() {
      return !this.loggedInUser || this.loggedInUserIsOTPUserAndOnWrongPage;
    },
    ...mapGetters(['error', 'loggedInUser']),
  }
};
</script>

<style scoped>
.content-container {
  margin: 0;
}

.notification-banner-container {
  margin: 0 auto;
  max-width: 75rem;
  padding: calc(1.5em + 1vw) 0.5em;
}
</style>